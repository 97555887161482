import { AccessorFn, ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { AmountColumnDef, EditableAmountColumnProps } from "./types";
import { HeaderCell } from "components/miloDesignSystem/molecules/table/components/header/Header";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";
import styles from "../CreateTableColumns.module.css";
import { useState } from "react";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { LoaderCell } from "components/miloDesignSystem/molecules/table/components/tableLoader/TableLoader";
import { mainListBigUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";

export const amountColumn = <
  T,
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown
>(
  accessorFn: AccessorFn<T, number | string>,
  columnDef: AmountColumnDef<T, TData, TError, TVariables, TContext>,
): ColumnDef<T> => {
  const baseColumnHelper = createColumnHelper<T>();
  return baseColumnHelper.accessor(accessorFn, {
    id: columnDef.header,
    size: columnDef.size,
    header: () => <HeaderCell className="text-right">{columnDef.header}</HeaderCell>,
    cell: info => {
      const value = info.getValue();
      if (columnDef.isLoading || columnDef.error)
        return <LoaderCell height={mainListBigUiSchema.cell.height} width={columnDef.size} />;
      if (columnDef.editable)
        return <EditableAmountColumn columnDef={columnDef} row={info.row} value={value} />;
      return (
        <AmountDisplay
          amount={value ? String(value) : "0.00"}
          className={styles.amountDisplay}
          currency={columnDef.amountDisplayProps?.currency}
          decimal={columnDef.amountDisplayProps?.decimal}
          integer={columnDef.amountDisplayProps?.integer}
        />
      );
    },
  }) as AmountColumnDef<T>;
};

const EditableAmountColumn = <
  T,
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown
>({
  columnDef,
  row,
  value,
}: EditableAmountColumnProps<T, TData, TError, TVariables, TContext>) => {
  const [isEditing, setIsEditing] = useState(false);

  if (!isEditing)
    return (
      <div className={styles.editableCell} onClick={() => setIsEditing(true)}>
        <AmountDisplay
          amount={value ? String(value) : "0.00"}
          className={styles.amountDisplay}
          currency={columnDef.amountDisplayProps?.currency}
          decimal={columnDef.amountDisplayProps?.decimal}
          integer={columnDef.amountDisplayProps?.integer}
        />
      </div>
    );
  return (
    <div className={styles.editInput}>
      <TextField.Async
        autoFocus
        mutationHook={columnDef.editable!.mutationHook}
        onBlur={() => setIsEditing(false)}
        size="small"
        transformQueryData={value => columnDef.editable!.transformQueryData(value, row)}
        type="number"
        value={value as string}
      />
    </div>
  );
};
