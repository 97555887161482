import customersIcon from "assets/images/customers.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import favicon from "assets/images/favicons/fv_customers.svg";
import { customers } from "./routes/customers";
import { dashboard } from "./routes/dashboard";
import businessCenterIcon from "assets/images/businessCenter.svg";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { financesBalance } from "../finances/routes/financesBalance";

export const crm: ModuleNavigation = {
  favicon,
  labelBackground: colorPalette.red200,
  label: "Kontrahenci",
  iconBackground: colorPalette.red600,
  icon: businessCenterIcon,
  menuIcon: customersIcon,
  name: "CRM",
  url: "/crm",
  navigationSections: [{ navigationList: [dashboard, financesBalance, customers] }],
};
