import { queryFetch } from "apiConnectors/queryFetch";
import { ManufacturingStage, PostManufacturingSchema, PostManufacturingStage } from "./models";
import { ManufacturingSchema } from "api/manufacturingNew/models";
import { UUID } from "api/types";
import { parsePatchData } from "utilities/parsePatchData";
import { Assign } from "utility-types";

const postManufacturingStage = (data: PostManufacturingStage) =>
  queryFetch<ManufacturingStage>({
    method: "POST",
    url: "/manufacturing/schemas/stages",
    data,
  });

const postManufacturingSchema = (data: PostManufacturingSchema) =>
  queryFetch<ManufacturingSchema>({
    method: "POST",
    url: "/manufacturing/schemas/entries",
    data,
  });

const patchManufacturingStage = (data: Assign<Partial<ManufacturingStage>, { id: UUID }>) =>
  queryFetch<ManufacturingStage>({
    method: "PATCH",
    url: "/manufacturing/schemas/stages/" + data.id,
    data: parsePatchData(data),
  });

export const manufacturingSchemaApi = {
  patchManufacturingStage,
  postManufacturingSchema,
  postManufacturingStage,
};
