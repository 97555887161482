import { CustomModal } from "components/utils/customModal";
import styles from "../../ManufacturingSchemasList.module.css";
import { Button } from "components/common";
import { useManufacturingStages } from "api/manufacturingNew/hooks";
import { queryString } from "utilities";
import { useState } from "react";
import { AdvancedSelect } from "components/common/advancedSelect/AdvancedSelect";
import { useMutation } from "hooks/useMutation";
import { postStageSchemaAssignment } from "api/manufacturingNew/calls";
import { useQueryUtils, useSelector, useToastr } from "hooks";
import { useQueryClient } from "react-query";
import { manufacturingNewKeys } from "api/manufacturingNew/keys";
import cx from "classnames";
import { ManufacturingSchema, StageSchemaAssignment } from "api/manufacturingNew/models";
import { assertIsDefined } from "utilities/assertIsDefined";
import { getAnyErrorKey } from "utilities";
import { Spinner } from "components/utils";
import { ManufacturingStage } from "api/manufacturing/schemas/models";

interface ModalProps {
  close: () => void;
  isOpen: boolean;
  manufacturingSchema: ManufacturingSchema;
}

export const AssignStagesToSchemaModal = ({ close, isOpen, manufacturingSchema }: ModalProps) => {
  const queryClient = useQueryClient();
  const { rollback, handleMutate } = useQueryUtils();
  const toastr = useToastr();
  const me = useSelector(store => store.auth.user!);
  const manufacturingSchemaKey = manufacturingNewKeys.manufacturingSchema(manufacturingSchema.id);
  const { data: manufacturingStages, isLoading } = useManufacturingStages(
    queryString.stringify({ pageSize: 999 }),
  );

  const [selectedStageId, setSelectedStageId] = useState<ManufacturingStage["id"] | null>(null);
  const selectedStage = manufacturingStages.find(stage => stage.id === selectedStageId);

  const isStageAlreadyAssigned = Boolean(
    manufacturingSchema.stages.find(stage => stage.stageId === selectedStageId),
  );

  const postMutation = useMutation(postStageSchemaAssignment, {
    onMutate: toUpdate => {
      const newStageAssignment = {
        ...toUpdate,
        ...selectedStage,
        code: "",
        stageId: selectedStageId,
        addedBy: me,
      } as StageSchemaAssignment;

      const prevSchema = handleMutate<ManufacturingSchema>(
        manufacturingSchemaKey,
        currentSchema => {
          assertIsDefined(currentSchema);
          const newStages = [...currentSchema.stages, newStageAssignment];
          return { ...currentSchema, stages: newStages };
        },
      );

      return { prevSchema };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(manufacturingNewKeys.manufacturingSchemas());
      queryClient.invalidateQueries(manufacturingSchemaKey);
      close();
    },
    onError: (error, previous) => {
      rollback(manufacturingSchemaKey, previous, error);
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  });

  if (!manufacturingStages) return null;

  const handleSubmit = () => {
    assertIsDefined(selectedStage);
    const position = manufacturingStages.length + 1;
    return postMutation.mutate({
      name: selectedStage?.name,
      schema: manufacturingSchema.id,
      stage: selectedStage?.id,
      position,
      parent: null,
    });
  };

  if (isLoading) {
    return (
      <CustomModal
        overrides={{
          container: { className: styles.modalContainer },
        }}
        isOpen={isOpen}
        close={close}
        title="Dodaj etap do schematu produkcyjnego"
      >
        <div className="position-relative d-flex justify-content-center">
          <Spinner color="blue" size="big" text="Trwa wczytywanie" />
        </div>
      </CustomModal>
    );
  }
  return (
    <CustomModal
      overrides={{
        container: { className: styles.modalContainer },
      }}
      isOpen={isOpen}
      close={close}
      title="Dodaj etap do schematu produkcyjnego"
    >
      <>
        <div className="p-3 d-flex gap-4 flex-column pb-5">
          <AdvancedSelect
            itemsWidth={360}
            width={360}
            inputPlaceholder="Szukaj etapu..."
            items={manufacturingStages.map(stage => {
              return {
                id: stage.id,
                name: stage.name,
                filters: {
                  name: stage.name,
                },
              };
            })}
            onChange={item => {
              setSelectedStageId(item?.id || null);
            }}
            selectedItem={selectedStageId}
            itemToDisplaySelected={item => (
              <div className="d-flex align-items-center body-14 fw-500">
                {item ? item.name : "Przypisz"}
              </div>
            )}
            overrides={{
              toggleButton: { style: { minHeight: "32px", border: "1px solid #ccc" } },
              list: { className: styles.customList },
            }}
          />

          {isStageAlreadyAssigned && !postMutation.isLoading && (
            <h2 className={cx("text-color-darkgrey")}>Ten etap jest już przypisany</h2>
          )}
        </div>

        <div className="d-flex align-items-center gap-3 p-3 borderTop">
          <Button
            className="mt-2 uppercase fs-12"
            kind="primary"
            disabled={isStageAlreadyAssigned || !selectedStageId}
            onClick={handleSubmit}
          >
            Dodaj
          </Button>
        </div>
      </>
    </CustomModal>
  );
};
