import { useMutation } from "hooks/useMutation";
import { manufacturingSchemaApi } from "./api";
import { getAnyErrorKey } from "utilities";
import { PostManufacturingSchema, PostManufacturingStage } from "./models";
import { FormikHelpers } from "formik";
import { assertIsDefined } from "utilities/assertIsDefined";
import { manufacturingSchemasKeys } from "./keys";

const usePatchManufacturingStage = () => {
  return useMutation(manufacturingSchemaApi.patchManufacturingStage, ({ queryUtils }) => ({
    onMutate: toUpdate => {
      const prevDetails = queryUtils.handleMutate(
        manufacturingSchemasKeys.manufacturingStage.details(toUpdate.id),
        toUpdate,
      );

      const prevList = queryUtils.handlePaginatedListUpdate(
        manufacturingSchemasKeys.manufacturingStage.list(),
        toUpdate.id,
        toUpdate,
      );

      return { prevDetails, prevList };
    },
    onError: (error, toUpdate, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      queryUtils.rollback(
        manufacturingSchemasKeys.manufacturingStage.details(toUpdate.id),
        onMutateReturn.prevDetails,
        error,
      );
      queryUtils.rollbackList(
        manufacturingSchemasKeys.manufacturingStage.list(),
        onMutateReturn.prevList,
        toUpdate.id,
      );
    },
  }));
};

const usePostManufacturingSchema = (close: () => void) => {
  const createManufacturingSchema = useMutation(
    manufacturingSchemaApi.postManufacturingSchema,
    ({ queryClient, toastr }) => ({
      onSuccess: payload => {
        queryClient.invalidateQueries();
        close();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Dodano schemat produkcyjny: ${payload.name}`,
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const handleSubmit = (
    values: PostManufacturingSchema,
    actions: FormikHelpers<PostManufacturingSchema>,
  ) => {
    createManufacturingSchema.mutate(values, {
      onSuccess: () => actions.setSubmitting(false),
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };

  return handleSubmit;
};

const usePostManufacturingStage = (close: () => void) => {
  const createManufacturingStage = useMutation(
    manufacturingSchemaApi.postManufacturingStage,
    ({ queryClient, toastr }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries();
        close();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Dodano etap produkcyjny",
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const handleSubmit = (
    values: PostManufacturingStage,
    actions: FormikHelpers<PostManufacturingStage>,
  ) => {
    createManufacturingStage.mutate(values, {
      onSuccess: () => actions.setSubmitting(false),
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };

  return handleSubmit;
};

export const manufacturingSchemaActions = {
  usePatchManufacturingStage,
  usePostManufacturingSchema,
  usePostManufacturingStage,
};
