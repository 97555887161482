import shoppingCardIcon from "assets/images/shoppingCartPink.svg";
import salesIcon from "assets/images/store.svg";
import homeIcon from "assets/images/homeLight.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import favicon from "assets/images/favicons/fv_sales.svg";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { MdiShoppingCart } from "components/miloDesignSystem/atoms/icons/MdiShoppingCart";
import { MdiTrolley } from "components/miloDesignSystem/atoms/icons/MdiTrolley";
import { MdiDocument } from "components/miloDesignSystem/atoms/icons/MdiDocument";
import { MdiStore } from "components/miloDesignSystem/atoms/icons/MdiStore";
import { MdiFactory } from "components/miloDesignSystem/atoms/icons/MdiFactory";
import { MdiPayments } from "components/miloDesignSystem/atoms/icons/MdiPayments";
import { MdiStickyNote2 } from "components/miloDesignSystem/atoms/icons/MdiStickyNote2";
import { Dashboard } from "routes/sales/Dashboard";

export const sales: ModuleNavigation = {
  favicon,
  labelBackground: colorPalette.magenta200,
  label: "Sprzedaż",
  iconBackground: "#431d87",
  icon: shoppingCardIcon,
  menuIcon: salesIcon,
  name: "SALES",
  url: "/sales",
  navigationSections: [
    {
      navigationList: [
        {
          url: "dashboard",
          label: "Pulpit",
          icon: homeIcon,
          subSection: <></>,
          routing: Dashboard,
          isAccessible: false,
        },
        {
          url: "orders",
          label: "Zamówienia",
          icon: MdiShoppingCart,
          subSection: <></>,
          routing: () => <></>,
          isAccessible: false,
        },
        {
          url: "complaints",
          label: "Reklamacje",
          icon: MdiTrolley,
          subSection: <></>,
          routing: () => <></>,
          isAccessible: false,
        },
        {
          url: "manufacturing-orders",
          label: "Zlecenia do dostawców",
          icon: MdiTrolley,
          subSection: <></>,
          routing: () => <></>,
          isAccessible: false,
        },
        {
          url: "offers",
          label: "Oferty",
          icon: MdiDocument,
          subSection: <></>,
          routing: () => <></>,
          isAccessible: false,
        },
        {
          url: "sales-accounts",
          label: "Konta sprzedażowe",
          icon: MdiStore,
          subSection: <></>,
          routing: () => <></>,
          isAccessible: false,
        },
        {
          url: "producers",
          label: "Producenci",
          icon: MdiFactory,
          subSection: <></>,
          routing: () => <></>,
          isAccessible: false,
        },
        {
          url: "price-lists",
          label: "Cenniki",
          icon: MdiPayments,
          subSection: <></>,
          routing: () => <></>,
          isAccessible: false,
        },
        {
          url: "sms-template",
          label: "Szablony SMS",
          icon: MdiStickyNote2,
          subSection: <></>,
          routing: () => <></>,
          isAccessible: false,
        },
      ],
    },
  ],
};
