import { Courier } from "api/shipping/models";
import { InfoLabel } from "components/common/infoLabel";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import styles from "../RightPanel.module.css";
import { AsyncInput } from "components/utils";
import { shippingActions } from "api/shipping/actions";
import { ShippingService } from "constants/shippingService";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  courier: Courier;
}

export const GeneralInfoSection = ({ courier }: Props) => {
  const nameMutation = shippingActions.useCourierPatchMutation();
  const userNameMutation = shippingActions.useCourierPatchMutation();
  const passwordMutation = shippingActions.useCourierPatchMutation();

  return (
    <>
      <RightPanelSection padding="px-3 pt-0 pb-0">
        <div className="d-flex align-items-start justify-content-between gap-2 ">
          <div className="flex-1">
            <InfoLabel title="kurier">
              <Typography fontSize="14" fontWeight="700">
                {courier.provider === ShippingService["NOT CONNECTED"]
                  ? EMPTY_VALUE
                  : courier.provider}
              </Typography>
            </InfoLabel>
            <InfoLabel title="nazwa">
              <div>
                <AsyncInput
                  disabled={nameMutation.isLoading}
                  inProgress={nameMutation.isLoading}
                  onChange={value =>
                    nameMutation.mutate({
                      id: courier.id,
                      toUpdate: { name: value },
                    })
                  }
                  overwrites={{
                    container: { className: styles.input },
                    input: { className: styles.input },
                  }}
                  placeholder="wpisz nazwę"
                  value={courier.name}
                />
              </div>
            </InfoLabel>
            <InfoLabel title="nazwa użytkownika">
              <div>
                <AsyncInput
                  disabled={userNameMutation.isLoading}
                  inProgress={userNameMutation.isLoading}
                  onChange={value =>
                    userNameMutation.mutate({
                      id: courier.id,
                      toUpdate: { username: value },
                    })
                  }
                  overwrites={{
                    container: { className: styles.input },
                    input: { className: styles.input },
                  }}
                  placeholder="wpisz nazwę użytkownika"
                  value={courier.username}
                />
              </div>
            </InfoLabel>
            <InfoLabel title="hasło użytkownika">
              <div>
                <AsyncInput
                  disabled={passwordMutation.isLoading}
                  inProgress={passwordMutation.isLoading}
                  onChange={value =>
                    passwordMutation.mutate({
                      id: courier.id,
                      toUpdate: { password: value },
                    })
                  }
                  overwrites={{
                    container: { className: styles.input },
                    input: { className: styles.input },
                  }}
                  placeholder="wpisz hasło użytkownika"
                  value={courier.password}
                />
              </div>
            </InfoLabel>
          </div>
        </div>
      </RightPanelSection>
    </>
  );
};
