import { CountryCode, DayOfTheWeek, EmployeeKind, EmployeeKindExtended } from "CONSTANTS";
import { ManufacturingStage } from "api/manufacturing/schemas/models";
import { UUID, UserPartial } from "api/types";
import { BaseUser, User, UserWithInitials } from "api/users/models";
import { colorType } from "components/common/stateLabel";

export interface ManufacturingPlan {
  created: string;
  collectionsSlotsSummary: {
    collections: Collection[];
    orders: Order[];
  } | null;
  createdBy: ManufacturingPlanCreatedBy;
  days: WorkingDay[];
  employees: ManufacturingPlanEmployee[];
  id: UUID;
  isConfirmed: boolean;
  masterStage: ManufacturingPlanMasterStage | null;
  name: string;
  shelfFilters: ShelfFilters;
  signature: string;
  status: ManufacturingPlanStatus;
  rangeOfDates: {
    firstDate: string;
    lastDate: string;
  };
  slotsSummary: SlotsSummary;
  sourcesSummary: SourcesSummary[];
}

export interface ManufacturingPlanListItem {
  user: UserWithInitials;
  countries: CountryCode[];
  created: string;
  lastUpdatedAt: string;
  createdBy: UserWithInitials;
  employees: EmployeeOnListItem[];
  id: string;
  isConfirmed: boolean;
  weeksOfYear: number[];
  masterStage: MasterStage;
  name: string;
  rangeOfDates: {
    firstDate: string;
    lastDate: string;
  };
  signature: string;
  status: ManufacturingPlanStatus;
  workingDays: WorkingDay[];
  slotsSummary: {
    taken: number;
    total: number;
  };
}

export type EmploymentType = "INTERNAL" | "EXTERNAL";

export interface BaseEmployee extends Pick<User, "firstName" | "lastName"> {
  isDeleted: boolean;
  kind: EmployeeKind;
  number: string;
}

export interface ManufacturingPlanEmployee extends BaseEmployee {
  avatar: BaseUser["avatar"];
  employeeWorkingDays: EmployeeWorkingDay[];
  slots: {
    percentage: number;
    taken: number;
    total: number;
  };
}

interface EmployeeOnListItem extends Omit<BaseEmployee, "isDeleted"> {
  avatar: string | null;
  dateOfDismissal: string | null;
  dateOfEmployment: string | null;
  id: number;
  isActive: boolean;
  phone: string;
}

export interface ShelfFilters {
  routes: {
    ids?: number[];
    departureDate?: string;
    isNull?: boolean;
  };
  orderGroups: {
    ids?: number[];
    departureDate?: string;
    isNull?: boolean;
  };
  orders: {
    createdAfter?: string;
    createdBefore?: string;
    deliveryMethod?: string;
    isCanceled?: boolean;
    isConfirmed?: boolean;
    shippingService?: number;
  };
  products: {
    categories?: number[];
  };
}

export const manufacturingPlanStatusDict: Record<ManufacturingPlanStatus, string> = {
  NOT_STARTED: "Nierozpoczęty",
  IN_PROGRESS: "W toku",
  FINISHED: "Zakończony",
};

type Collection = {
  id: string;
  signature: string;
  finishedCount: number;
  otherCount: string;
  percentage: string;
};

type Order = {
  id: string;
  signature: string;
  finishedCount: number;
  otherCount: number;
  percentage: number;
};

type MasterStage = {
  backgroundColor: string;
  code: string;
  id: string;
  implementedBy: string;
  name: string;
  textColor: string;
};

export interface SlotsSummary {
  taken: number;
  total: number;
}

export interface SourcesSummary {
  sourceColor: string;
  sourceId: number;
  sourceSignature: string;
}

export interface ManufacturingPlanMasterStage {
  backgroundColor: string;
  id: UUID;
  name: string;
  textColor: string;
}

export interface ManufacturingPlanCreatedBy {
  avatar: string | null;
  firstName: string;
  id: number;
  isActive: boolean;
  isSuperuser: boolean;
  lastName: string;
  phone: string;
}

export interface ManufacturingSchema {
  createdAt: string;
  signature: string;
  createdBy: UserPartial;
  description: string;
  id: string;
  lastUpdatedAt: string;
  name: string;
  stages: StageSchemaAssignment[];
}

export interface PostStageSchemaAssignment {
  name: string;
  position: number;
  parent: string | null;
  stage: string;
  schema: string;
}

export interface PostStageEmployeeAssingment {
  setting: UUID;
  employee: number;
  position: number;
}

export interface PostStageEmployeeAssignmentPreview extends PostStageEmployeeAssingment {
  createdAt: string;
  id: UUID;
  slots: number;
}

export interface Day {
  name: DayOfTheWeek;
  position: number;
  isWeekend: boolean;
  shortName: string;
  isSelected: boolean;
}

export interface VisiblePreviousStage {
  id: UUID;
  isVisible: boolean;
  position: number;
  stage: {
    id: UUID;
    name: string;
  };
}

export interface SettingsEmployee extends Pick<User, "firstName" | "lastName" | "avatar"> {
  id: UUID;
  isDeleted: boolean;
  employeeId: number;
  kind: EmployeeKindExtended;
  number: string;
}

export interface Settings {
  daysOfTheWeek: Day[];
  employees: SettingsEmployee[];
  id: UUID;
  name: string;
  numberOfWorkingDays: number;
  skipHolidays: boolean;
  startAt: DayOfTheWeek;
  visiblePreviousStages: VisiblePreviousStage[];
}

export interface ManufacturingStageSchema {
  createdAt: string;
  createdBy: Pick<User, "firstName" | "id" | "lastName" | "avatar"> | null;
  description: string;
  id: UUID;
  name: string;
  signature: string;
}

export enum EmployedPlace {
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
}

export const employedPlaceDict: Record<EmployedPlace, string> = {
  EXTERNAL: "kontrahent",
  INTERNAL: "wewn.",
};

export interface ManufacturingEmployeeListItem extends BaseEmployee {
  avatar: BaseUser["avatar"];
  id: number;
  lastWithdrawal: {
    amount: number;
    date: string;
  };
  balance: number;
  employedPlace: EmployedPlace;
}

export interface ManufacturingEmployee extends ManufacturingEmployeeListItem {
  email: string;
  phone: string;
  employedPlace: EmployedPlace;
}

export type PostEmployee = Pick<
  ManufacturingEmployee,
  "firstName" | "lastName" | "email" | "phone"
> & {
  date: string;
  kind: EmployeeKindExtended;
  employedPlace: EmployedPlace;
  number: string;
  company?: string;
};

export interface StageSchemaAssignment extends ManufacturingStage {
  stageId: UUID;
  code: string;
  position: number;
  addedBy: UserPartial;
  parent: string | null;
}

export type PatchManufacturingStageColumnSet = {
  stageColumnSet: string;
};

export type ManufacturingPlanStatus = "NOT_STARTED" | "IN_PROGRESS" | "FINISHED";

export const statusStyleDictionary: Record<ManufacturingPlanStatus, colorType> = {
  NOT_STARTED: "grey",
  IN_PROGRESS: "transparentViolet",
  FINISHED: "green",
};

export interface EmployeeWorkingDay {
  categorySummary: CategorySummary[];
  date: string;
  id: string;
  isAvailable: boolean;
  manufacturingWorkingUnits: ManufacturingWorkingUnit[];
  slots: {
    default: number;
    extra: number;
  };
}

export type EmployeesTab =
  | "all"
  | "upholsterer"
  | "seamstresses"
  | "cutter"
  | "carpenter"
  | "packer";

interface CategorySummary {
  code: string | null;
  color: string | null;
  name: string | null;
  slots: number;
}

export interface ManufacturingWorkingUnit {
  id: string;
  index: Index;
  note: string;
  priority: ManufacturingItemPriority;
  signature: string;
  source: ManufacturingItemSource;
  status: ManufacturingItemStatus;
  visiblePreviousStages: ManufacturingStage[];
}
export interface WorkingDay {
  id: string;
  date: string;
  dayOfTheWeek: DayOfTheWeek;
  isHoliday: boolean;
  slots: {
    taken: number;
    total: number;
    percentage: number;
    finished: number;
  };
}
export interface ManufacturingItemSource {
  backgroundColor: string;
  id: number;
  signature: string;
}

type ManufacturingItemPriority = "A" | "B" | "C";
type ManufacturingItemStatus = "PENDING" | "IN_PROGRESS" | "DONE";

interface Index {
  attributesValues: string;
  category: {
    code: string | null;
    color: string | null;
    name: string | null;
  };
  name: string;
}

export type Tab = "departed" | "active" | "all";

export interface AddPreviousStageToManufacturingSettings {
  position: number;
  setting: UUID;
  stage: UUID;
}

export interface AddPreviousStageToManufacturingSettingsPreview
  extends AddPreviousStageToManufacturingSettings {
  createdAt: string;
  id: UUID;
  isVisible: boolean;
}
