import { Pagination, UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { orderKeys } from "./keys";
import {
  FreeSingleItemQuantitiesDetails,
  FreeSingleItemQuantitiesListItem,
  OrderForInvoicing,
  OrderSingleItemQuantityWarehouseLocation,
  OrderWarehouseLocation,
} from "./models";

const getOrdersForInvoicing = (search: string = ""): ApiFetcher<Pagination<OrderForInvoicing>> => ({
  key: orderKeys.ordersForInvoicing(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/for-invoicing" + search,
    }),
});

const getOrderWarehouseLocations = (
  search: string = "",
): ApiFetcher<Pagination<OrderWarehouseLocation>> => ({
  key: orderKeys.orderWarehouseLocations(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/warehouse-locations" + search,
    }),
});

const getSingleItemQuantityWarehouseLocations = (
  search: string = "",
): ApiFetcher<Pagination<OrderSingleItemQuantityWarehouseLocation>> => ({
  key: orderKeys.singleItemQuantityWarehouseLocations(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/single-item-quantity-warehouse-locations" + search,
    }),
});

const getOrdersFreeSingleItemQuantities = (
  search: string = "",
): ApiFetcher<Pagination<FreeSingleItemQuantitiesListItem>> => ({
  key: orderKeys.ordersFreeSingleItemQuantities(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/free-single-item-quantities" + search,
    }),
});

const getOrderFreeSingleItemsQuantitiesDetails = (
  id: UUID,
): ApiFetcher<FreeSingleItemQuantitiesDetails> => ({
  key: orderKeys.orderFreeSingleItemQuantitiesDetails(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/orders/free-single-item-quantities/${id}`,
    }),
});

export const orderApi = {
  getOrderWarehouseLocations,
  getSingleItemQuantityWarehouseLocations,
  getOrdersFreeSingleItemQuantities,
  getOrderFreeSingleItemsQuantitiesDetails,
  getOrdersForInvoicing,
};
