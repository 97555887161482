import { createPaginatedKey } from "api/keys";
import { UUID } from "api/types";

export const orderKeys = {
  itemsWithSingleQuantities: createPaginatedKey("itemsWithSingleQuantities"),
  singleItemQuantities: createPaginatedKey("singleItemQuantities"),
  singleItemQuantitiesDetails: createPaginatedKey("singleItemQuantitiesDetails"),
  ordersFreeSingleItemQuantities: createPaginatedKey("ordersFreeSingleItemQuantities"),
  singleItemQuantitiesForRoute: createPaginatedKey("singleItemQuantitiesForRoute"),
  orderWarehouseLocations: createPaginatedKey("orderWarehouseLocations"),
  singleItemQuantityWarehouseLocations: createPaginatedKey("singleItemQuantityWarehouseLocations"),
  orderFreeSingleItemQuantitiesDetails: (id: UUID) => ["orderFreeSingleItemQuantitiesDetails", id],
  ordersForInvoicing: createPaginatedKey("ordersForInvoicing"),
};
