import { FilterType } from "components/common/filters/types";
import { FilterList } from "components/common/moduleNavigation/components/filtersSection/filterList/FilterList";
import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiReturn } from "components/miloDesignSystem/atoms/icons/MdiReturn";
import { wmsReturnConstants } from "constants/returns";
import { ReturnsRouting } from "routes/wms/ReturnsRouting";

export const returns: ModuleLink = {
  url: "returns",
  label: "Zwroty",
  icon: MdiReturn,
  subSection: (
    <FilterList
      filters={[
        {
          type: FilterType.Select,
          kind: "label",
          label: "status",
          name: "status",
          options: Object.entries(wmsReturnConstants.statusOptions).map(([value, details]) => ({
            label: details.label,
            value,
          })),
        },
      ]}
    />
  ),
  routing: ReturnsRouting,
};
